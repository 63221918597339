<template>
  <nav class="nav">
    <!-- logo -->
    <router-link
      to="/"
      class="logo">
      <img
        src="/imgs/hslogo.png"
        alt="logo for Hartsville-Trousdale Historical Society"
        class="logo-image">
    </router-link>

    <!-- navlist -->
    <ul class="nav-list">
      <li class="list-item">
        <router-link
          to="/"
          class="item-link">
          HOME
        </router-link>
      </li>

      <li class="list-item">
        <router-link
          to="/about"
          class="item-link">
          ABOUT
        </router-link>
      </li>

      <li class="list-item">
        <router-link
          to="/contact"
          class="item-link">
          CONTACT
        </router-link>
      </li>

      <li class="list-item">
        <router-link
          to="/meetings"
          class="item-link">
          MEETINGS
        </router-link>
      </li>

      <li class="list-item">
        <router-link
          to="/gallery"
          class="item-link">
          GALLERY
        </router-link>
      </li>

      <li class="list-item">
        <router-link
          to="/maps"
          class="item-link">
          MAPS
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<style lang=scss scoped>
  $nav-background-color: #7C1910;
  $nav-font-color: transparentize(#F5E1A8, 0.4);
  $nav-active-link-color: #F5E1A8;

  .nav {
    align-items: center;
    background-color: $nav-background-color;
    display: flex;
    height: 70px;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    transition: height .5s linear .5s;
    width: 100%;
    z-index: 1;
  }

  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: width .5s linear .5s, transform .5s linear, left .5s linear;
    width: 95px;
  }

  .logo-image {
    height: 100%;
    width: 100%;
  }

  .nav-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 16px;
    opacity: 0;
    position: relative;
    pointer-events: none;
    transition: all .5s linear;
    width: 0;
  }

  .item-link {
    color: $nav-font-color;
    padding: 8px;
    text-decoration: none;
  }

  .router-link-exact-active {
    color: $nav-active-link-color;
    text-decoration: underline;
  }

  @media screen and (min-width: 680px) {

    .nav-list {
      opacity: 1;
      pointer-events: auto;
      width: auto;
    }

    .logo{
      left: 0;
      position: relative;
      transform: translateX(16px);
      width: 95px;
    }
  }

  @media screen and (min-width: 768px) {
    .nav {
      position: fixed;
    }
  }

  @media screen and (min-width: 1440px) {

    .nav-list {
      opacity: 1;
    }

    .logo {
      left: 0;
      transform: translateX(16px);
      width: 95px;
    }
  }

</style>
