import mixpanel from 'mixpanel-browser';

const { NODE_ENV: env, VUE_APP_MIXPANEL_KEY: mxKey } = process.env;
const isDevelopmentEnvironment = env === 'development';
const isProductionEnvironment = env === 'production';

const mxTrack = (type, options) => {
  if (!isProductionEnvironment) return;
  mixpanel.init(mxKey, {debug: isDevelopmentEnvironment});
  mixpanel.track(type, options);
}

export default {
  install: (app) => {
    app.config.globalProperties.$mxTrack = mxTrack;
  }
}