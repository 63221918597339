import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/main.scss';
import mixpanelPlugin from './plugins/mixpanel';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
library.add(faFacebook)

export const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(mixpanelPlugin)
  .use(store)
  .use(router)
  .mount('#app')
