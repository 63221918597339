<template>
  <main
    :class="{show: showModal}"
    class="modal"
    @click="hideModal">
    <img
      src="/imgs/iom/iom-june.jpg"
      alt="Image of the month photograph"
      class="iom-image">
  </main>
</template>
<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    hideModal: function () {
      this.$emit('hideModal');
    }
  }
};
</script>
<style lang=scss scoped>
  .modal {
    opacity: 0;
    height: 100%;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    transition: all 1s ease;
    width: 100%;
    z-index: 4;
    &::before {
      background-color: black;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0.85;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 4;

    }
  }

  .show {
    display: block;
    opacity: 1;
    pointer-events: auto;
  }

  .iom-image {
    position: relative;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    z-index: 4;
  }

  @media screen and (min-width: 1024px) {
    .iom-image {
      width: 50%;
      max-width: 850px;
    }
  }
</style>
