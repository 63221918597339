<template>
  <section class="sidebar-template">
    <aside
      id="sidebar"
      :class="{'sidebar--open': sidebarIsOpen}"
      class="sidebar"
      @click="toggleIcon">
      <sidebar-nav
        :class="{'nav--open': sidebarIsOpen}"
        @toggle="toggleIcon" />
    </aside>
  </section>

</template>

<script>
import SidebarNav from './SidebarNav.vue';
export default {
  components: {
    SidebarNav
  },
  props: {
    sidebarIsOpen: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      iconStatus: '',
      navStatus: '',
      sidebarStatus: ''

    };
  },
  methods: {
    toggleIcon: function () {
      this.$emit('toggleSidebar');
    }
  }
};
</script>

<style lang=scss scoped>

  .sidebar {
    height: 100%;
    position: fixed;
    pointer-events: none;
    width: 100%;
    z-index: 2;

    &::before {
      background-color: rgba(0,0,0,0.7);
      content: '';
      display: block;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      width: 100%;
    };
  }

  .sidebar--open {
    &.sidebar::before {
      opacity: 1;
      pointer-events: auto;
      transition: opacity .3s ease-in-out;

    }
  }
</style>
