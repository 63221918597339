<template>
  <figure class="hero">
    <article class="hero-text">
      <p class="hero-title">
        Hartsville-Trousdale County Historical Society
      </p>
      <p class="hero-tagline">
        Trousdale County history
        <span
          :class="{fadeout: fade}"
          class="tag-words">
          {{ currentTag }}
        </span>
      </p>
    </article>

  </figure>
</template>
<script>
export default {
  data: function () {
    return {
      fade: false,
      currentTag: '',
      currentIndex: 0,
      tags: ['discussed', 'shared', 'observed']
    };
  },
  mounted: function () {
    this.currentTag = this.tags[0];
    setInterval(this.updateWord, 6000);
  },
  methods: {
    updateWord: function () {
      setTimeout(this.fadeword, 1000);
      setTimeout(this.changeTag, 2000);
      setTimeout(this.fadeword, 3000);
    },
    changeTag: function () {
      this.currentIndex += 1;
      if (this.tags[this.currentIndex]) {
        this.currentTag = this.tags[this.currentIndex];
      } else {
        this.currentIndex = 0;
        this.currentTag = this.tags[this.currentIndex];
      }
    },
    fadeword: function () {
      this.fade = !this.fade;
    }
  }
};
</script>

<style lang=scss scoped>
  $tag-words-color: transparentize(#E8CDA2, 0);
  $tag-words-background: transparentize(#944846, 0.2);
  $text-color: transparentize(#944846, 0);
  .hero {
    box-sizing: border-box;
    height: 800px;
    padding: 0 16px;
    position: relative;
    transform-style: inherit;
  }

  .hero-text {
    color: $text-color;
    font-size: 24px;
    font-weight: bold;
    margin-top: -100px;
    position: relative;
    text-align: center;
    top: 60%;
    transform: translateY(-50%);;
  }

  .hero-tagline {
    font-size: 18px;
    font-style: italic;
    margin-top: 16px;
  }

  .tag-words {
    background-color: $tag-words-background;
    border-radius: 2.5px;
    color: $tag-words-color;
    display: inline-block;
    font-size: 24px;
    margin-top: 8px;
    padding: 8px;
    transition: all .5s ease;
    width: 130px;
  }

 .hero::before {
    background-image: url(/public/imgs/courthouseOverlay.png);
    background-size: cover;
    bottom: 0;
    content: " ";
    left: 0;
    min-height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    display: block;
 }

 .fadeout {
    opacity: 0;
 }

 .fadein {
    opacity: 1;
 }

 @media screen and (min-width: 768px) {

   .hero-text {
      font-size: 32px;
    }

   .hero-tagline {
     font-size: 24px;
   }

   .tag-words {
     font-size: 28px;
   }

   .hero::before {
      background-image: url(/public/imgs/courthouseOverlay.png);
      background-position: 10% 30%;
      background-size: cover;
      bottom: 0;
      content: " ";
      left: 0;
      min-height: 100vh;
      position: absolute;
      right: 0;
      top: 0;
      transform-origin: center center 0;
      transform: translateZ(-1px) scale(2);
      z-index: -1;
      display: block;
   }
 }

</style>
