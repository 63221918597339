<template>
  <main class="video-plate">
    <figure class="title">
      <p class="title-text tagline">
        Historical site tours given by Trousdale County Historian
      </p>
      <p class="title-text name">
        John Oliver
      </p>
    </figure>
    <section class="videos">
      <figure class="video-wrap">
        <iframe
          class="video"
          src="https://www.youtube.com/embed/xcdoD8Kb7jk"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen />
      </figure>
      <figure class="video-wrap">
        <iframe
          class="video"
          src="https://www.youtube.com/embed/4QXqGyBIuPU"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen />
      </figure>
      <figure class="video-wrap">
        <iframe
          class="video"
          src="https://www.youtube.com/embed/MmtPqdU8vDo"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen />
      </figure>
      <figure class="video-wrap">
        <iframe
          class="video"
          src="https://www.youtube.com/embed/Ld_cqblGGN4"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen />
      </figure>
    </section>
  </main>
</template>

<style lang=scss scoped>
  $title-tag-color: transparentize(#BDAA8D, 0);
  $title-name-color: transparentize(#761C1D, 0);
  $border-bottom-color: transparentize(#BDAA8D, 0);
  .title {
    color: $title-tag-color;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5;
    margin: auto;
    padding-bottom: 16px;
    padding-top: 16px;
    text-align: center;
    width: 300px;
  }

  .name {
    border-bottom: 3px solid $border-bottom-color;
    color: $title-name-color;
    font-style: italic;
    padding-bottom: 8px;
  }

  .video-plate {
    background-color: whiteSmoke;
  }

  .videos {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 16px;
  }

  .video-wrap {
    max-width: 560px;
    height: 315px;
    padding-bottom: 16px;
    width: 100%;
    margin: 0 0.5em;
  }

  .video {
    height: 315px;
    margin: 0 8px;
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    .videos {
      flex-direction: row;
      justify-content: space-around;
    }
  }

</style>
