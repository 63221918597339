<template>
  <div class="home-page">
    <hero-comp />
    <main class="content">
      <meeting-comp />
      <som-comp />
      <quote-comp />
      <archives-comp />
      <iom-home-comp class="iom-home" />
      <maps-comp />
      <officers-comp />
      <book-comp />
      <video-comp />
    </main>
  </div>
</template>

<script>
import HeroComp from './sections/HeroComponent.vue';
import MeetingComp from './sections/MeetingComponent.vue';
import ArchivesComp from './sections/ArchivesComponent.vue';
import QuoteComp from './sections/QuoteComponent.vue';
import MapsComp from './sections/MapsComponent.vue';
import IomHomeComp from './sections/IomHomeComponent.vue';
import OfficersComp from './sections/OfficersComponent.vue';
import SomComp from './sections/SomComponent.vue';
import BookComp from './sections/BookComponent.vue';
import VideoComp from './sections/VideoComponent.vue';

export default {
  components: {
    HeroComp,
    MeetingComp,
    ArchivesComp,
    QuoteComp,
    MapsComp,
    IomHomeComp,
    OfficersComp,
    SomComp,
    BookComp,
    VideoComp
  }
};
</script>

<style lang=scss scoped>
  .home-page {
    height: 100%;
    padding-top: .1px;
  }

  .content {
    background: gainsboro;
    height: 50%;
    min-height: 1200px;
    position: relative;
  }

  .iom-home {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .iom-home {
      display: block;
    }
  }
</style>
