<template>
  <section class="quote">
    <main class="wrap">
      <img
        src="/imgs/quot_begin.png"
        alt="large opening quote symbol"
        class="quote-open">
      <p class="quote-text">
        Gentlemen, This is truly the temple of the Gods
      </p>
      <section class="quote-credit">
        <p class="author">
          ~ General James Winchester
        </p>
        <p class="tag">
          Describing Templow Tennessee located in Trousdale County.
        </p>
      </section>
      <img
        src="/imgs/quot_end.png"
        alt="large closing quote symbol"
        class="quote-close">
    </main>
  </section>
</template>
<style lang=scss scoped>
  $quote-background-color: #944846;
  $quote-text-color: transparentize(#E8CDA2, 0.2);

  .quote {
    background-color: $quote-background-color;
    color: $quote-text-color;
    letter-spacing: 1px;
    padding: 16px 0;
    position: relative;
    z-index: 0;
  }

  .author {
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 80px;
    padding-top: 16px;
    position: relative;
    z-index: 0;
  }

  .wrap {
    margin: auto;
    max-width: 571px;
    padding: 32px;
    position: relative;
  }

  .quote-text {
    font-size: 24px;
    font-style: italic;
    padding-left: 32px;
  }

  .tag {
    line-height: 1.2;
    padding-left: 92px;
  }

  .quote-open {
    position: absolute;
    left: 16px;
    top: 16px;
    width: 75px;
    z-index: -1
  }

  .quote-close {
    position: absolute;
    right: 16px;
    bottom: 10%;
    width: 75px;
    z-index: -1;
  }

  @media screen and (min-width: 768px) {
    .wrap {
      font-size: 20px;
    }
  }
</style>
