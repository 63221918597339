<template>
  <main class="sidebar-nav-list">
    <ul class="list-wrap">

      <li class="list-item">
        <figure
          class="link-wrap"
          @click="$emit('toggle')">
          <router-link
            to="/"
            class="list-link">
            HOME
          </router-link>
        </figure>
      </li>

      <li
        class="list-item">
        <figure
          class="link-wrap"
          @click="$emit('toggle')">
          <router-link
            to="/about"
            class="list-link">
            ABOUT
          </router-link>
        </figure>
      </li>

      <li
        class="list-item">
        <figure
          class="link-wrap"
          @click="$emit('toggle')">
          <router-link
            to="/contact"
            class="list-link">
            CONTACT
          </router-link>
        </figure>
      </li>

      <li
        class="list-item">
        <figure
          class="link-wrap"
          @click="$emit('toggle')">
          <router-link
            to="/meetings"
            class="list-link">
            MEETINGS
          </router-link>
        </figure>
      </li>

      <li
        class="list-item">
        <figure
          class="link-wrap"
          @click="$emit('toggle')">
          <router-link
            to="/gallery"
            class="list-link">
            GALLERY
          </router-link>
        </figure>
      </li>

      <li
        class="list-item">
        <figure
          class="link-wrap"
          @click="$emit('toggle')">
          <router-link
            to="/maps"
            class="list-link">
            MAPS
          </router-link>
        </figure>
      </li>
    </ul>

  </main>
</template>
<style lang=scss scoped>
  $navlist-background-color: transparentize(#944846, 0.1);
  $navlist-text-color: #944846;
  $nav-active-link-color: #F5E1A8;
  $border-bottom-color: #944846;
  $item-bottom-color: transparentize(#944846, 0.9);

  .list-wrap {
    margin-top: 16px;
  }

  .list-item {
    border-bottom: 1px solid $item-bottom-color;
    box-sizing: border-box;
    padding: 8px;
    margin-bottom: 8px;
  }

  .link-wrap {
    display: inline;
  }

  .list-link {
    box-sizing: border-box;
    color: $navlist-text-color;
    text-decoration: none;
  }

  .router-link-exact-active {
    border-bottom: 2px solid $border-bottom-color;
    font-size: 18px;
    font-weight: bold;
  }

</style>
