<template>
  <div class="flexbox">
    <section class="iom">

      <router-link
        to="/iom"
        class="iom-link">
        <figure class="iom-wrapper">
          <img
            src="/imgs/iom/iom-june.jpg"
            alt="photo of the month"
            class="image">
        </figure>
        <figcaption class="image-description">
          <P class="image-description-text">
            Image of the month
          </P>
          <p class="month">
            {{ getMonth }}
          </p>

          <p class="description-paragraph">
            My how fashions change! This picture, although made at a Nashville photo studio, shows four sisters, all members of the Bass family: Linnie Bass, Sidney Bass, Terry, Miranda Bass Hall, Lucy Bass White holding her son Gordon White. Made around 1890.
          </p>
          <p class="description-paragraph">
            ~ Photo donated by Edna Mae Terry Estate
          </p>
        </figcaption>
      </router-link>
    </section>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  computed: {
    getMonth: function () {
      if (moment().month() === 7) {
        return moment().month(8).format('MMMM');
      } else {
        return moment().format('MMMM');
      }
    }
  }
};
</script>

<style lang=scss scoped>
  $plate2-background-color: transparentize(#E8CDA2, 0.2);
  $text-highlight-color: #944846;
  $text-color: #440e09;
  $button-hover-color: transparentize(#944846, 0.8);
  $link-hover-color: transparentize(#944846, 0.4);
  $image-frame-color: #ffffff;
  .flexbox {
    display: none;
  }

  .iom {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    .flexbox {
      background-image: url('/public/imgs/picturesBackground.png');
      background-size: cover;
      box-sizing: border-box;
      display: flex;
      flex: 1;
      height: 100%;
      padding: 32px;
      order: 2;
    }
    .iom {
      background-color: $image-frame-color;
      display: block;
      padding: 16px;
      margin: auto;
      transition: all .5s ease;
      width: 600px;

      &:hover {
        box-shadow: 3px 3px 2px 3px #888888;
        transform: scale(1.1);
      }

    }

    .iom-link {
      text-decoration: none;
    }

    .wrap {
      flex: 1;
    }

    .image {
      display: block;
      margin: 0 auto;
      width: 400px;
    }

    .image-description {
      color: $text-color;
      text-align: center;
      font-size: 20px;
    }

    .description-paragraph {
      font-size: 16px;
    }

    .month {
      font-weight: bold;
      font-style: italic;
    }
  }
</style>
