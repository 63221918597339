<template>
  <main class="officers-section">
    <section class="title">
      <p class="title-text">
        Historical Society Officers
      </p>
    </section>
    <section class="officer-list">
      <figure class="officer-item president">
        <p class="officer-name">
          John Oliver
        </p>
        <p class="officer-title">
          President
        </p>
      </figure>

      <figure class="officer-item president">
        <p class="officer-name">
         Jim Bills 
        </p>
        <p class="officer-title">
          Vice-President
        </p>
      </figure>

      <figure class="officer-item treasurer">
        <p class="officer-name">
          Rose Allen Kelly
        </p>
        <p class="officer-title">
          Treasurer
        </p>
      </figure>
      
      <figure class="officer-item secratary">
        <p class="officer-name">
          Julia Herod Carnahan
        </p>
        <p class="officer-title">
          Secretary
        </p>
      </figure>
    </section>

    <figure class="divide" />

    <section class="tagline">
      <p class="tagline-text">
        Collecting, preserving, sharing Trousdale County History.
      </p>
    </section>
  </main>
</template>
<style lang=scss scoped>
  $officers-background-color: transparentize(#ffffff, 0);
  $officers-text-color: transparentize(#7a1a15, 0);
  $officers-title-color: transparentize(#AD966E, 0.2);
  $divide-color: transparentize(#7a1a15, 0);
  $tagline-text-color: transparentize(#AD966E, 0.2);

  .officers-section {
    background-color: $officers-background-color;
    color: $officers-text-color;
    padding: 8px;
  }
  .title {
    color: $officers-title-color;
    padding: 16px;
    text-align: center;
  }

  .title-text {
    border-bottom: 3px solid $officers-text-color;
    display: inline;
    font-size: 20px;
    font-style: italic;
    font-weight: bold;
  }

  .officer-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 16px;
    padding-top: 8px;
  }

  .officer-item {
    flex: 1;
    padding: 8px;
    text-align: center;
  }

  .officer-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .officer-title {
    color: $officers-title-color;
    font-style: italic;
  }

  .divide {
    background-color: $divide-color;
    height: 3px;
    margin: 0 auto 20px;
    width: 275px;
  }

  .tagline {
    color: $tagline-text-color;
    font-style: italic;
    padding-bottom: 16px;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    .title {
      padding-bottom: 32px;
    }

    .title-text {
      font-size: 32px;
    }

    .officer-list {
      flex-direction: row;
      padding-bottom: 32px;
    }

    .president {
      order: 2;
    }

    .secratary {
      order: 3;
    }

    .treasurer {
      order: 1;
    }

    .tagline {
      padding-bottom: 32px;
    }
  }
</style>
