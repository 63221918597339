<template>
  <section class="footer">

    <main class="content">

      <section class="location">
        <figure class="location-map">
          <iframe
            class="location-map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11297.144423143156!2d-86.16711684156293!3d36.39757297366266!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x514af48bc723814c!2sHartsville%2FTrousdale+County+Archives!5e0!3m2!1sen!2sus!4v1531149378501"
            allowfullscreen />
        </figure>

        <article class="address">
          <p class="address-item address-name">
            Hartsville-Trousdale County Historical Society
          </p>
          <p class="address-item address-street">
            328 Broadway, Hartsville TN, 37074
          </p>
          <p class="address-item address-email">
            johnlynn@nctc.com
          </p>
          <p class="address-item address-phone">
            Phone (615) 633-4717
          </p>
          <p class="address-item address-hours" />
          <p class="address-item address-map">
            <a
              href="https://goo.gl/maps/p1vrsab2ehS2"
              target="_blank">
              View Map
            </a>
          </p>

        </article>
      </section>

      <figure class="divider--vertical" />

      <section class="meeting">
        <p class="meeting-header">
          Join Us
        </p>
        <p class="meeting-text">
          Our meetings are open to the public on the second Saturday of the month.
        </p>
      </section>

      <figure class="divider--vertical" />

      <figure class="logo">
        <a
          class="logo-link"
          href="/">
          <img
            class="link-image"
            src="/imgs/hslogo-footer.png"
            alt="Hartsville-Trousdale County Historical Society">
        </a>
      </figure>

    </main>

    <section class="legal">
      <p class="legal-text">
        &copy; Hartsville-Trousdale County Historical Society
      </p>
      <p class="legal-text">
        Site developed by
        <a href="kentdevs.com">Kent Moreland Web Development</a>
      </p>
    </section>
  </section>
</template>

<style lang=scss scoped>
  $footer-heading-color: #F5E1A8;
  $footer-divider-color: #2e2305;
  $footer-background-color: transparentize(#AD966E, 0.2);
  $footer-text-color: #2e2305;

  .footer {
    background-color: $footer-background-color;
    color: $footer-text-color;
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: space-between;
    padding: 40px 16px 16px;
    position: relative;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .location {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    padding: 0 16px;
  }

  .address {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    justify-content: center;
    height: 150px;
    line-height: 1.5;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    text-align: center;
  }

  .address-name {
    color: $footer-text-color;
    font-size: 16px;
    font-weight: bold;
  }

  .location-map {
    display: none;
    height: 150px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 150px;
  }

  .meeting {
    display: none;
    flex: 1;
    line-height: 1.5;
    padding: 0 16px;
    text-align: center;
    width: 350px;
  }

  .meeting-header {
    font-weight: bold;
    margin: 15px 0;
  }

  .logo {
    flex: 1;
    width: 100%;
  }

  .logo-link {
    top: 50%;
    transform: translateY(100%);
    transition: transform .5s linear 3s;
    display: none;
    height: 100%;
    margin: auto;
    padding: 0 16px;
    position: relative;
    width: 300px;
  }

  .link-image {
    width: 100%;
    margin: auto;
  }

  .legal {
    font-size: 12px;
    text-align: center;
    width: 100%;
  }

  .divider--vertical {
    background-color: $footer-divider-color;
    display: none;
    height: 150px;
    width: 2px;
  }

  @media screen and (min-width: 665px) {
    .content {
      flex-direction: row;
    }

    .location {
      flex: 1;
    }

    .logo-link {
      width: 150px;
      display: block;
      transform: translateY(-50%);
    }

    .link-image {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .location-map {
      display: block;
    }

    .address {
      text-align: left;
      justify-content: center;
      left: 0;
      transform: none;
      margin-left: 8px;
    };

    .address-map {
      display: none;
    }
  }

  @media screen and (min-width: 775px) {
    .logo-link {
      width: 300px;
    }
  }

  @media screen and (min-width: 1024px) {
    .name {
      font-size: 32px;
    }

    .divider--vertical {
      display: block;
    }

    .meeting {
      display: block;
    }
  }
</style>
