<template>
  <main class="som-plate">
    <article class="content">
      <p class="tagline">
        Join us for our
        <span class="highlight">
          {{ getFeatured.month }}
        </span> meeting.
      </p>
      <p class="speaker">
        Speaker:
        <span class="highlight">
          {{ getFeatured.speaker }}
        </span>
      </p>
      <template v-if="getFeatured.website">
        <p class="website">
          <a
            :href="getFeatured.website"
            class="weblink"
            target="_blank">
            website
          </a>
        </p>
      </template>
    </article>
  </main>
</template>
<script>
import meetings from '../../../assets/data/meetingInfo.js';
import moment from 'moment';
export default {
  data: function () {
    return {
      meetings,
      currentMonth: ''
    };
  },
  computed: {
    getFeatured: function () {
      // CURRENT MONTH
      // get the current month
      let currentMonth = moment().month() + 1;

      // SECOND SATURDAY
      // get the first day of the current month
      const firstDay = moment().date(1);

      // get the first saturday of the current month
      const firstSaturday = firstDay.day('saturday');
      // get the second saturday of the current month
      const secondSaturday = firstSaturday.day(13);

      // SECOND SATURDAY PASSED?
      // check the difference between the current day and the second saturday
      const diff = moment().diff(secondSaturday, 'days');

      // if the difference is positive increment the current month.
      if (diff > 0) {
        currentMonth += 1;
      }
      const meeting = this.meetings.filter((currentMeeting) => {
        return currentMonth === currentMeeting.number;
      });
      return meeting[0];
    }
  },
  mounted () {
    this.currentMonth = moment().format('MMMM');
  }
};
</script>
<style lang=scss scoped>
  $background-color: transparentize(#65806D, 0.3);
  $text-color: transparentize(#ffffff, 0);
  $text-highlight: transparentize(#e6d2b2, 0);
  $weblink-color: transparentize(#b8c7bc, 0);
  $weblink-hover-color: transparentize(#b8c7bc, 0.4);

  .som-plate {
    background-image: url('/public/imgs/woodBackground.jpg');
    background-size: cover;
    background-position: 30% 0;
    background-color: $background-color;
    color: $text-color;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 1px;
    padding: 16px;
    text-align: center;
  }

  .content {
    margin: auto;
    width: 300px;
  }
  .weblink {
    color: $weblink-color;
    text-decoration: none;

    &:hover {
      color: $weblink-hover-color;
      text-decoration: underline;
    }
  }

  .highlight {
    color: $text-highlight;
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
  }

</style>
