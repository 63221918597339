<template>
  <section class="map-plate">
    <main class="description">
      <p class="title">Observe</p>
      <article class="text-block">
        <p class="text-paragraph">
          Check out Historical Hartsville. A map of historical and notable places to see in Trousdale County.
        </p>

        <p class="text-paragraph">
          Take the Battle of Hartsville Driving Tour! An interactive map of locations with information about each stop as you visit notable sites from the Civil War's Battle of Hartsville.
        </p>
        <figure class="button">
          <router-link
            to="/maps"
            class="button-link">
            View Maps
          </router-link>
        </figure>
      </article>
    </main>
  </section>
</template>

<style lang=scss scoped>
  $background-color: transparentize(#944846, 0.1);
  $title-color: #440e09;
  $text-color: #E8CDA2;
  $button-background-color: transparentize(#E8CDA2, 0.3);
  $button-hover-color: transparentize(#E8CDA2, 0);
  $button-text-color: #440e09;
  .map-plate {
    background-color: $background-color;
    background-image: url(/public/imgs/mapsBackground.png);
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: center;
    line-height: 1.5;
    padding: 32px;
    text-align: center;
  }

  .title {
    border: 3px solid $title-color;
    font-size: 32px;
    margin: 0 auto 32px;
    width: 200px;
  }

  .text-block {
    color: $text-color;
    margin: 0 auto;
    width: 250px;
  }

  .text-paragraph {
    margin-bottom: 16px;
  }

  .button {
    background-color: $button-background-color;
    border-radius: 5px;
    margin: 32px auto 0;
    position: relative;
    width: 150px;

    &:hover {
      background-color: $button-hover-color;
    }

    &:active {
      top: 5px;
    }

    .button-link {
      box-sizing: border-box;
      color: $button-text-color;
      display: block;
      padding: 16px;
      text-decoration: none;
    }
  }

  @media screen and (min-width: 768px) {
    .text-block {
      font-size: 20px;
      width: 375px;
    }
  }
</style>
