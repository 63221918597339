<template>
  <main class="book-plate">
    <section class="flexgroup">
      <article class="description">
        <div class="textgroup">

          <p class="title-text">
            Hartsville and Trousdale County
          </p>
          <p class="description-title">
            A book by John L. Oliver and the Trousdale County Historical Society
          </p>
          <p class="booksite">
            <a
              href="https://www.arcadiapublishing.com/Products/9780738554181"
              class="booklink"
              target="_target">
              Purchase online from Acadia Publishing. >>
            </a>
          </p>
        </div>
      </article>
      <figure class="book">
        <img
          src="/imgs/JohnOliverBook.png"
          alt="Cover image of Hartsville and Trousdale County book."
          class="book-image">
      </figure>
    </section>
  </main>
</template>
<style lang=scss scoped>
  $book-image-background-color: #781B19;
  $description-background-color: #e5d0af;
  $text-color: #781B19;
  $link-color: #65806D;
  $link-hover-color: transparentize(#65806D, 0.4);

  .flexgroup {
    align-items: center;
    background-color: $description-background-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .description {
    color: $text-color;
    line-height: 1.5;
    padding-bottom: 16px;
    padding-top: 32px;
    width: 100%;
  }

  .textgroup {
    margin: 0 auto;
    width: 350px;
  }

  .title-text {
    font-size: 24px;
    font-weight: bold;
  }

  .booklink {
    color: $link-color;
    font-style: italic;
    text-decoration: none;

    &:hover {
      color: $link-hover-color;
      text-decoration: underline;
    }
  }

  .book {
    background-color: $book-image-background-color;
    width: 100%;

  }

  .book-image {
    display: block;
    margin: auto;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media screen and (min-width: 768px) {
    .flexgroup {
      align-items: center;
      flex-direction: row;
    }
    .description {

    }

    .book {
    }

    .book-image {
      padding-top: 32px;
    }
  }
</style>
