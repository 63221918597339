<template>
  <section class="archives-plate">
    <iom-comp class="iom-comp" />
    <main class="wrap">
      <p class="title"> Share</p>
      <article class="description">
        <p class="text">
          The Archives building contains historical documents from the Trousdale County Area.
        </p>

        <p class="text">
          The Archives is open on
          <span class="highlight">
            Wednesdays
          </span>

          <span class="highlight"> 9:00
            <span class="small-cap">
              am
            </span>
          </span> until

          <span class="highlight">
            3:00
            <span class="small-cap">
              pm
            </span>
          </span> or by

          <router-link
            to="/contact"
            class="contact-link">
            appointment
          </router-link>.
        </p>
        <p class="text archives-message">
          *The archives uses vounteer hours to keep the building open on Wednesdays. Please contact us to confirm any plans to visit, or contact us if you would like to volunteer.
        </p>
        <figure class="button">
          <router-link
            to="/gallery"
            class="button-link">
            View Gallery
          </router-link>
        </figure>
      </article>
    </main>
  </section>
</template>

<script>
import IomComp from './IomComponent.vue';
export default {
  components: {
    IomComp
  }
};
</script>
<style lang=scss scoped>
  $plate2-background-color: transparentize(#E8CDA2, 0.2);
  $text-highlight-color: #944846;
  $text-color: #440e09;
  $button-hover-color: transparentize(#944846, 0.8);
  $link-hover-color: transparentize(#944846, 0.4);

  .archives-plate {
    background-color: $plate2-background-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5;
    min-height: 500px;
  }

  .title {
    border: 3px solid $text-color;
    font-size: 32px;
    margin: 0 auto 32px;
    text-align: center;
    width: 200px;
  }

  .description {
    text-align: center;
    margin: 0 auto;
    width: 290px;
  }

  .text {
    margin-bottom: 16px;
  }

  .button {
    border: 2px solid $text-color;
    border-radius: 5px;
    margin: 32px auto 0;
    position: relative;
    width: 150px;

    &:hover {
      background-color: $button-hover-color;
    }

    &:active {
      top: 5px;
    }
  }

  .button-link {
    color: $text-color;
    display: block;
    padding: 16px;
    text-align: center;
    text-decoration: none;
  }

  .contact-link {
    color: $text-highlight-color;
    font-weight: bold;

    &:hover {
      color: $link-hover-color;
    }
  }

  .highlight {
    color: $text-highlight-color;
    font-style: italic;
  }

  .archives-message {
    color: $text-highlight-color;
    font-style: italic;
  }

  @media screen and (min-width: 768px) {
    .description{
      font-size: 20px;
      width: 375px;
    }
  }

  @media screen and (min-width: 1024px) {
    .archives-plate {
      align-items: center;
      display: flex;
      flex-direction: row;
      min-height: 500px;
      justify-content: center;
    }

    .wrap {
      flex: 1;
    }

    .iom-comp {
      flex: 1;
      height: 100%;
    }
  }

</style>
