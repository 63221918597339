<template>
  <main
    :class="{show: showSlideshow}"
    class="slideshow">
    <figure
      class="closeIcon"
      @click="closeSlideshow">
      &#935;
    </figure>
    <img
      :src="img"
      alt="photo from image gallery"
      class="image">
    <nav class="navigation">
      <p
        class="arrow next"
        @click="nextImage">
        &#9002;
      </p>
      <p
        class=" arrow previous"
        @click="prevImage">
        &#9001;
      </p>
    </nav>
  </main>

</template>
<script>
export default {
  props: {
    showSlideshow: {
      type: Boolean,
      required: true
    },
    img: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    nextImage: function () {
      this.$emit('nextImage');
    },
    prevImage: function () {
      this.$emit('prevImage');
    },
    closeSlideshow: function () {
      this.$emit('closeSlideshow');
    }
  }
};
</script>

<style lang=scss scoped>
  .slideshow {
    opacity: 0;
    height: 100%;
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    transition: all 1s ease;
    width: 100%;
    z-index: 4;
    &::before {
      background-color: black;
      content: "";
      height: 100%;
      opacity: 0.85;
      position: absolute;
      width: 100%;
      z-index: 4;
    }

  }

  .closeIcon {
    color: white;
    cursor: pointer;
    font-size: 24px;
    margin: 16px 0 0 32px;
    position: absolute;
    padding: 8px;
    z-index: 6;
  }

  .show {
    display: block;
    opacity: 1;
    pointer-events: auto;
  }

  .image {
    left: 50%;
    position: absolute;
    top: 50%;
    transform:translate(-50%, -50%);
    height: auto;
    max-width: 75%;
    z-index: 6;
  }

  .navigation {
    height: 100%;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 5;

    &:hover {
      .arrow {
        opacity: 1;
      }
    }
  }

  .arrow {
    align-items: center;
    box-sizing: border-box;
    background: transparent;
    color: white;
    cursor: pointer;
    display: block;
    opacity: 0;
    display: flex;
    justify-content: center;
    font-size: 32px;
    height: 100%;
    position: absolute;
    text-align: center;
    z-index: 5;

    &:hover {
      opacity: 1;
    }
  }

  .next {
    padding-left: 16px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .previous {
    left: 0;
    padding-right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (min-width: 520px) {
    .image {
      max-height: 75%;
      width: auto;
    }
  }

</style>
