<template>
  <section class="meeting-plate">
    <figure class="image">
      <img
        src="/imgs/trousdaleCountyOutline.png"
        alt="outline of Trousdale County"
        class="trousdale-image">
    </figure>
    <main class="description">
      <p class="title">Discuss</p>
      <article class="text-block">
        <p class="text-paragraph">
          Join us for our meetings on the <span class="highlight">second Saturday</span> of each month.
        </p>
        <p class="text-paragraph">
          Meetings take place at the <span class="highlight"> Archives </span> building and start at <span class="highlight">2pm</span>.
        </p>
      </article>
      <figure class="button">
        <router-link
          to="/meetings"
          class="button-link">
          Learn More
        </router-link>
      </figure>
    </main>
  </section>
</template>

<style lang=scss scoped>
  $text-color: #440e09;
  $text-highlight-color: #944846;
  $accent-font-color: #7C1910;
  $nav-font-color: #F5E1A8;
  $button-background-color: transparentize(#E8CDA2, 0.3);
  $button-hover-color: transparentize(#E8CDA2, 0);

  .meeting-plate {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: center;
    line-height: 1.5;
    padding: 32px;
    text-align: center;
  }

  .trousdale-image {
    margin: 0 auto 64px;
    width: 130px;
  }

  .title {
    border: 3px solid $text-color;
    font-size: 32px;
    margin: 0 auto 32px;
    width: 200px;
  }

  .text-block {
    margin: 0 auto;
    width: 300px;
  }

  .text-paragraph {
    margin-bottom: 16px;
  }

  .button {
    background-color: $button-background-color;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 32px auto 0;
    position: relative;
    width: 150px;

    &:hover {
      background-color: $button-hover-color;
    }

    &:active {
      top: 5px;
    }
  }

  .button-link {
    box-sizing: border-box;
    color: $text-color;
    display: block;
    height: 100%;
    padding: 16px;
    text-decoration: none;
    width: 100%;
  }

  .highlight {
    color: $text-highlight-color;
    font-style: italic;
  }

  @media screen and (min-width: 768px) {
    .text-block {
      font-size: 20px;
      width: 375px;
    }
  }

  @media screen and (min-width: 1024px) {
    .meeting-plate {
      align-items: center;
      flex-direction: row;
    }

    .image {
      flex: 1;
    }

    .trousdale-image {
      width: 330px;
    }

    .description {
      flex: 1;
    }
  }

</style>
