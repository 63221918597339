<template>
  <div class="template-wrap">
    <slideshow-component
    :img="image"
    :show-slideshow="showSlideshow"
    @nextImage="nextImage"
    @prevImage="prevImage"
    @closeSlideshow="closeSlideshow"/>

    <iom-modal-component
      :show-modal="showModal"
      @hideModal="toggleModal" />

    <sidebar-icon-component
    :icon-is-open="sidebarIsOpen"
    @toggleSidebar="toggleSidebar" />

    <nav-component />

    <sidebar-component
    :sidebar-is-open="sidebarIsOpen"
    @toggleSidebar="toggleSidebar"/>

    <section
      id="test"
      class="site-wrapper">
      <main class="site-body">
        <transition name="fade">
          <router-view
            class="router-view"
            @showModal="toggleModal"
            @showSlideshow="toggleSlideshow" />
        </transition>
      </main>

      <footer-component />
    </section>
  </div>
</template>
<script>
import SlideshowComponent from './components/Gallery/sections/SlideshowComponent.vue';
import IomModalComponent from './components/Iom/sections/IomModalComponent.vue';
import SidebarIconComponent from './components/SidebarIconComponent.vue';
import NavComponent from './components/NavComponent.vue';
import SidebarComponent from './components/sidebar/SidebarComponent.vue';
import FooterComponent from './components/FooterComponent.vue' ;


export default {
  components: {
    SlideshowComponent,
    IomModalComponent,
    SidebarIconComponent,
    SidebarComponent,
    NavComponent,
    FooterComponent
  },
  data () {
    return {
      sidebarIsOpen: false,
      showModal: false,
      showSlideshow: false,
      slideshow: {
        columnNumber: '',
        currentColumn: '',
        currentIndex: '',
        column1: '',
        column2: '',
        column3: '',
        column4: ''
      },
      image: ''
    };
  },
  methods: {
    toggleSidebar: function () {
      this.sidebarIsOpen = !this.sidebarIsOpen;
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
    closeSlideshow: function () {
      this.showSlideshow = !this.showSlideshow;
    },
    toggleSlideshow: function (columns) {
      this.slideshow.columnNumber = Number.parseInt(columns.currentColumn);
      this.slideshow.currentColumn = `column${columns.currentColumn}`;
      this.slideshow.currentIndex = columns.currentIndex;
      this.slideshow.column1 = columns.column1;
      this.slideshow.column2 = columns.column2;
      this.slideshow.column3 = columns.column3;
      this.slideshow.column4 = columns.column4;
      this.updateImg();
      this.showSlideshow = !this.showSlideshow;
    },
    updateImg: function () {
      const images = this.slideshow[this.slideshow.currentColumn];
      const imageName = images[this.slideshow.currentIndex];
      this.image = `/imgs/GalleryImages/${imageName}`;
    },
    nextImage: function () {
      const images = this.slideshow[this.slideshow.currentColumn];
      const newIndex = this.slideshow.currentIndex + 1;
      if (images[newIndex]) {
        this.slideshow.currentIndex = newIndex;
        const imageName = images[newIndex];
        this.image = `/imgs/GalleryImages/${imageName}`;
      } else {
        this.incrementColumn();
        const images = this.slideshow[this.slideshow.currentColumn];
        const newIndex = 0;
        this.slideshow.currentIndex = newIndex;
        const imageName = images[newIndex];
        this.image = `/imgs/GalleryImages/${imageName}`;
      }
    },
    prevImage: function () {
      const images = this.slideshow[this.slideshow.currentColumn];
      const newIndex = this.slideshow.currentIndex -= 1;
      if (images[newIndex]) {
        this.slideshow.currentIndex = newIndex;
        const imageName = images[newIndex];
        this.image = `/imgs/GalleryImages/${imageName}`;
      } else {
        this.decrementColumn();
        const images = this.slideshow[this.slideshow.currentColumn];
        const newIndex = images.length - 1;
        this.slideshow.currentIndex = newIndex;
        const imageName = images[newIndex];
        this.image = `/imgs/GalleryImages/${imageName}`;
      }
    },
    incrementColumn: function () {
      const nextColumnNumber = this.slideshow.columnNumber + 1;
      const nextColumn = `column${nextColumnNumber}`;
      if (this.slideshow[nextColumn]) {
        this.slideshow.columnNumber = nextColumnNumber;
        this.slideshow.currentColumn = nextColumn;
      } else {
        this.slideshow.columnNumber = 1;
        this.slideshow.currentColumn = 'column1';
      }
    },
    decrementColumn: function () {
      const prevColumnNumber = this.slideshow.columnNumber - 1;
      const prevColumn = `column${prevColumnNumber}`;
      if (this.slideshow[prevColumn]) {
        this.slideshow.columnNumber = prevColumnNumber;
        this.slideshow.currentColumn = prevColumn;
      } else {
        this.slideshow.columnNumber = 4;
        this.slideshow.currentColumn = 'column4';
      }
    }
  }
}
</script>
<style lang=scss scoped>
  .template-wrap {
    height: 100%;
  }
  .site-wrapper {
    height: 100%;
  }

  .fade-enter-active, .fade-leave-active {
    transition-property: opacity;
    transition-duration: .25s;
  }

  .fade-enter-active {
    transition-delay: .25s;
  }

  .fade-enter, .fade-leave-active {
    opacity: 0
  }

  @media screen and (min-width: 768px) {
    .site-wrapper {
      height: 100%;
      perspective: 1px;
      transform-style: preserve-3d;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

</style>