<template>
  <figure
    id="icon"
    :class="{'icon--open': iconIsOpen}"
    class="icon"
    @click="toggleIcon">
    <span class="line" />
    <span class="line" />
    <span class="line" />
    <span class="line" />
  </figure>
</template>
<script>
export default {
  props: {
    iconIsOpen: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggleIcon: function () {
      this.$emit('toggleSidebar');
    }
  }
};
</script>
<style lang=scss scoped>
  $line-color: #F5E1A8;
  $icon-open-line-color: #954844;

  .icon {
    align-items: center;
    height: 21px;
    left: 12px;
    position: absolute;
    transition: opacity .5s ease-in-out;
    opacity: 1;
    top: 24.5px;
    width: 30px;
    z-index: 5;
  }

  .line {
    background-color: $line-color;
    border-radius: 12px;
    height: 4px;
    opacity: 1;
    position: absolute;
    transition: .25s ease-in-out;
    width: 100%;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2), &:nth-child(3) {
      top: 8px;
    }

    &:nth-child(4) {
      top: 16px;
    }
  }

  .icon--open {
    .line {
      background-color: $icon-open-line-color;
      &:nth-child(1) {
        top: 0;
        width: 0;
        left: 0;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 10px;
        width: 0;
        left: 0;
      }
    }
  }

  @media screen and (min-width: 680px) {
    .icon {
      opacity: 0;
      pointer-events: none;
    }
  }
</style>
