<template>
  <section
    :class="status"
    class="nav"
    @click="blockClicks">
    <header class="header">
      <figure class="logo">
        <img
          class="logo-image"
          src="/imgs/logo-maroon.svg"
          alt="Trousdale County Historical Society">
      </figure>
    </header>

    <sidebar-nav-list @toggle="pushEvent" />
  </section>
</template>

<script>
import SidebarNavList from './SidebarNavList.vue';
export default {
  components: {
    SidebarNavList
  },
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  methods: {
    blockClicks: function (e) {
      e.stopPropagation();
    },
    pushEvent: function () {
      this.$emit('toggle');
    }
  }
};

</script>

<style lang=scss scoped>
  $header-background-color: transparentize(#E8CDA2, 0.3);
  $nav-background-color: transparentize(#f5f5f5, 0);

  .logo-image {
    display: block;
    margin: auto;
    width: 245px;
  }

  .nav {
    background-color: $nav-background-color;
    height: 100%;
    position: relative;
    transition: transform .5s ease-in-out;
    transform: translateX(-104%);
    width: 61.8%;
  }

  .header {
    background-color: $header-background-color;
    height: 144px;
  }

  .nav--open {
    pointer-events: auto;
    transition: transform .5s ease-in-out;
    transform: translateX(0);
  }

</style>
