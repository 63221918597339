import { createRouter, createWebHistory } from 'vue-router'
import { app } from '../main';
import HomePage from '../components/Home/Home.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      title: 'HTC Historical Society | Home'
    }, 
    beforeEnter (to, from, next) {
      app.$mxTrack('Home');
      next();
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */'../components/About/About.vue'),
    meta: {
      title: 'HTC Historical Society | About us'
    },
    beforeEnter (to, from, next) {
      app.$mxTrack('About');
      next();
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../components/Contact/Contact.vue'),
    meta: {
      title: 'HTC Historical Society | Contact'
    },
    beforeEnter (to, from, next) {
      app.$mxTrack('Contact');
      next();
    }
  },
  {
    path: '/meetings',
    name: 'meetings',
    component: () => import(/* webpackChunkName: "meetings" */ '../components/Meetings/Meetings.vue'),
    meta: {
      title: 'HTC Historical Society | Meetings'
    },
    beforeEnter (to, from, next) {
      app.$mxTrack('Meeting');
      next();
    }
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '../components/Gallery/Gallery.vue'),
    meta: {
      title: 'HTC Historical Society | Gallery'
    },
    beforeEnter (to, from, next) {
      app.$mxTrack('Gallery');
      next();
    }
  },
  {
    path: '/maps',
    component: () => import(/* webpackChunkName: "maps" */ '../components/Maps/Maps.vue'),
    meta: {
      title: 'HTC Historical Society | Gallery'
    },
    beforeEnter (to, from, next) {
      app.$mxTrack('Maps');
      next();
    }
  },
  {
    path: '/iom',
    name: 'iom',
    component: () => import(/* webpackChunkName */ '../components/Iom/Iom.vue'),
    meta: {
      title: 'HTC Historical Society | Image of the Month'
    },
    beforeEnter (to, from, next) {
      app.$mxTrack('Image of the Month');
      next();
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const titleText = document.getElementsByTagName('title')[0];
  titleText.innerHTML = to.meta.title;
  next();
});

export default router
