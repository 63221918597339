const meetings = [
  {
    month: 'January',
    number: 1,
    announced: true,
    speaker: 'Loretta Ewing',
    website: '',
    blurb: 'Tennessee Jams & Jellies and T & L Honey'
  },
  {
    month: 'February',
    number: 2,
    announced: true,
    speaker: 'To be updated',
    website: '',
    blurb: ''
  },
  {
    month: 'March',
    number: 3,
    announced: true,
    speaker: 'Bill Friday',
    website: '',
    blurb: 'Speaking on 2 Sumner County inventors who developed a type of cotton gin'
  },
  {
    month: 'April',
    number: 4,
    announced: true,
    speaker: 'Jerry Poppin',
    website: '',
    blurb: 'The Barefoot Gardener'
  },
  {
    month: 'May',
    number: 5,
    announced: true,
    speaker: 'Key Dillard',
    website: '',
    blurb: 'Shaped Note Singing and Do Re Mi Singing School'
  },
  {
    month: 'June',
    number: 6,
    announced: true,
    speaker: 'Don Green',
    website: '',
    blurb: 'Author from Red Boiling Springs'
  },
  {
    month: 'July',
    number: 7,
    announced: true,
    speaker: 'To be updated',
    website: '',
    blurb: ''
  },
  {
    month: 'August',
    number: 8,
    announced: true,
    speaker: 'To be updated',
    website: '',
    blurb: ''
  },
  {
    month: 'September',
    number: 9,
    announced: true,
    speaker: 'To be updated',
    website: '',
    blurb: ''
  },
  {
    month: 'October',
    number: 10,
    announced: true,
    speaker: 'To be updated',
    website: '',
    blurb: ''
  },
  {
    month: 'November',
    number: 11,
    announced: true,
    speaker: 'To be updated',
    website: '',
    blurb: ''
  },
  {
    month: 'December',
    number: 12,
    speaker: 'No Meeting In December'
  }
];

module.exports = meetings;
