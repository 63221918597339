<template>
  <main class="iom-plate">
    <section class="iom">
      <figcaption class="image-title">
        <P class="image-title-text">
          Image of the month
        </P>
      </figcaption>
      <figure class="iom-wrapper">
        <router-link
          to="/iom"
          class="iom-link">
          <img
            :src="iom"
            alt="Historical Society photo of the month"
            class="image">
        </router-link>
      </figure>
      <figure class="month">
        <p class="month-text">
          {{ getMonth }}
        </p>
      </figure>
      <figure class="description">
        <p class="description-text">
          My how fashions change! This picture, although made at a Nashville photo studio, shows four sisters, all members of the Bass family: Linnie Bass, Sidney Bass, Terry, Miranda Bass Hall, Lucy Bass White holding her son Gordon White. Made around 1890.
        </p>
        <p class="description-text">
          ~ Photo donated by Edna Mae Terry Estate
        </p>
      </figure>
    </section>
  </main>
</template>
<script>
import moment from 'moment';
export default {
  data: function () {
    return {
      iom: '/imgs/iom/iom-june.jpg'
    };
  },
  computed: {
    getMonth: function () {
      if (moment().month() === 7) {
        return moment().month(8).format('MMMM');
      } else {
        return moment().format('MMMM');
      }
    }
  }
};
</script>

<style lang=scss scoped>
  $plate2-background-color: transparentize(#E8CDA2, 0.2);
  $text-highlight-color: #944846;
  $text-color: #440e09;
  $button-hover-color: transparentize(#944846, 0.8);
  $link-hover-color: transparentize(#944846, 0.4);
  $title-border-color: #440e09;
  $iom-frame-color: transparentize(#E8CDA2, 0.2);
  $iom-description-background-color: transparentize(#E8CDA2, 0.5);

  .iom-plate {
    background-color: #ffffff;
    background-image: url(/public/imgs/picturesBackground.png);
    background-size: cover;
    background-position: center;
    min-height: 500px;
    padding: 32px;
    position: relative;
  }

  .iom-wrapper {
    background-color: $iom-frame-color;
    border-radius: 5px;
    box-sizing: border-box;
    margin: auto;
    transition: all .5s ease;
    width: 250px;

    &:hover {
      box-shadow: 2px 2px 1px 2px #888888;
      transform: scale(1.1);
    }
  }

  .iom-link {
    display: block;
    width: 100%;
    height: 100%;
  }

  .image {
    box-sizing: border-box;
    display: block;
    padding: 8px;
    width: 250px;
  }

  .image-title {
    color: $text-color;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 10px;
    margin-bottom: 24px;
  }

  .image-title-text {
    border-bottom: 3px solid $title-border-color;
    display: inline;
    padding-bottom: 4px;
  }

  .description {
    background-color: $iom-description-background-color;
    margin: 16px auto 0;
    max-width: 500px;
    padding: 4px;
  }

  .description-text {
    color: $text-color;
    line-height: 1.5;
    margin: 16px auto;
    max-width: 500px;
  }

  .month {
    padding-top: 32px;
    text-align: center;
  }

  .month-text {
    color: $title-border-color;
    font-size: 32px;
    font-style: italic;
    font-weight: bold;
  }
</style>
